import React from "react"
import { Img } from "react-image"
import "./p-p-image.scss"

const PPImage = ({ item }) => {

    /* JSX */
    return (
        <Img
            className="p-p-image"
            src={item?.image?.originalSrc}
            loading="lazy"
            alt={item?.alt} />
    )
}

export default PPImage