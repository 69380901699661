import React, { useContext, useEffect } from "react"
import { LanguageContext } from "../../../../../context/language"
import { Link, useParams } from "react-router-dom"
import "./d-faq-category.scss"

const DFAQCategory = ({ item, setSelectedIndex }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { category } = useParams()

    /* Triggers */
    useEffect(() => {
        resetSelectedIndex()
    }, [category])

    /* Functions */
    const resetSelectedIndex = () => {
        setSelectedIndex(-1)
    }


    /* JSX */
    return (
        <Link
            to={`/faq/${item?.slug}`}
            className={"d-faq-category-container " + (category === item?.slug ? "selected" : "")}
            aria-label={item?.value}>
                {`${item?.value} ` + `(${item?.faqList?.length})`}
        </Link>
    )
}

export default DFAQCategory