import React, { useContext } from "react"
import { useTranslation } from "../../../../../hooks/useTranslation"
import { GlobalContext } from "../../../../../context/global"
import "./remove-notify-available-success-message.scss"

const RemoveNotifyAvailableSuccessMessage = () => {

    /* Global */
    const { dialog } = useContext(GlobalContext)
    const { t } = useTranslation()

    console.log("--------------------------------------------------")
    console.log("dialog: ", dialog)

    const title = dialog?.removeNotifyWhenAvailable?.res?.payload?.message?.title
    const body = dialog?.removeNotifyWhenAvailable?.res?.payload?.message?.body

    console.log("title: ", title)
    console.log("body: ", body)

    /* JSX */
    return (
        <div className="remove-notify-available-success-message-container">
            <h2>{t(title)}</h2>
            <p>{t(body)}</p>
        </div>
    )
}

export default RemoveNotifyAvailableSuccessMessage