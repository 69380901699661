import React from "react"
import { Helmet } from "react-helmet-async"

const MetaTags = ({ name, description, canonicalUrl }) => {

    /* JSX */
    return (
        <Helmet>
            <title>{name}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={name} />
            <meta property="og:description" content={description} />
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        </Helmet>
    )
}

export default MetaTags