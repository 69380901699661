import React, { useEffect, useRef, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { LanguageContext } from "../../../../../../context/language"
import "./faq-buttom.scss"

const FAQButtom = ({ item, open }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const navigate = useNavigate()

    /* Locale */
    const faqButtomContainerRef = useRef()

    /* Triggers */
    useEffect(() => {
        const cleanup = updateSugarClick()
        return cleanup
    }, [lang])

    /* Functions */
    const updateSugarClick = () => {
        setTimeout(() => {
            if (faqButtomContainerRef.current) {
                const sugarClick = faqButtomContainerRef.current.querySelector('span#sugarClick')
                const contactClick = faqButtomContainerRef.current.querySelector('span#contactClick')
                if (sugarClick) {
                    const handleSugarClick = () => {
                        if (lang === "he") {
                            navigate('/he/product/8895022956799')
                        } else {
                            navigate('/product/8895022956799')
                        }
                    }
                    sugarClick.addEventListener('click', handleSugarClick)
                    return () => {
                        sugarClick.removeEventListener('click', handleSugarClick)
                    }
                }
                if (contactClick) {
                    const handleContactClick = () => {
                        if (lang === "he") {
                            navigate('/he/contact')
                        } else {
                            navigate('/contact')
                        }
                    }
                    contactClick.addEventListener('click', handleContactClick)
                    return () => {
                        contactClick.removeEventListener('click', handleContactClick)
                    }
                }
            }
        }, 0)
    }

    /* JSX */
    return (
        <div
            ref={faqButtomContainerRef}
            className={"faq-buttom-container " + (open ? "open" : "")}
            dangerouslySetInnerHTML={{ __html: item?.answerAsHTML }}/>
    )
}

export default FAQButtom