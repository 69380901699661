import React from "react"
import "./loading-cover.scss"

const LoadingCover = ({ theme }) => {

    /* JSX */
    return (
        <div className={"loading-container " + theme}/>
    )
}

export default LoadingCover