import React from "react"
import "./d-p-t-button-up.scss"

const DPTButtonUp = ({ isOverflowingUp, startScroll, stopScroll }) => {

    /* JSX */
    return (
        <button aria-label="Scroll thumbnails up" className={"d-p-t-button-up-contaienr " + (isOverflowingUp ? "active" : "")}>
            <span 
                onPointerDown={() => startScroll("up")}
                onPointerUp={stopScroll}
                onMouseUp={stopScroll}
                onMouseLeave={stopScroll}
                className="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
    )
}

export default DPTButtonUp