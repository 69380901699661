import React, { useContext, useEffect, useState } from "react"
import { ShopifyContext } from "../../../../context/shopify"
import { CurrencyContext } from "../../../../context/currency"
import { formatPrice } from "../../../../lib/helpers"
import PPChangeCurrency from "./p-p-change-currency/p-p-change-currency"
import "./p-price.scss"

const PPrice = () => {

    /* Global */
    const { storeDisplay } = useContext(ShopifyContext)
    const { getConversion, currency, conversionRates } = useContext(CurrencyContext)

    /* Locale */
    const [listOpen, setListOpen] = useState(false)
    const [amount, setAmount] = useState(null)

    /* Triggers */
    useEffect(() => {
        updateAmount()
    }, [currency, storeDisplay, conversionRates])

    /* Functions */
    const updateAmount = async () => {
        if (storeDisplay?.variant?.availableForSale) {
            const convertedAmount = await getConversion({ to: currency, amount: storeDisplay?.variant?.priceV2?.amount })
            setAmount(convertedAmount)
        } else {
            setAmount(null)
        }
    }

    /* JSX */
    return (
        <div className="p-price-container">
            <h2>{formatPrice(amount, currency)}</h2>
            {/* {conversionRates?.[currency] && <PPChangeCurrency
                                                listOpen={listOpen}
                                                setListOpen={setListOpen}/>} */}
        </div>
    )
}

export default PPrice