import React, { useEffect, useContext } from "react"
import { GlobalContext } from "../../context/global"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { goToPageTop } from "../../lib/helpers"
import { useMedia } from "../../hooks/useMedia"
import { LanguageContext } from "../../context/language"
import MetaTags from "../../components/meta-tags/meta-tags"
import LoadingCover from "../../components/loading-cover/loading-cover"
import MTerms from "./m-terms/m-terms"
import DTerms from "./d-terms/d-terms"
import "./terms.scss"

const Terms = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { legalData } = useContext(GlobalContext)
    const { media } = useMedia()
    const { urllang, section } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
    }, [location])

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / תנאים`
        : `Ms-Sugar Skateshop / Terms`

    const description = lang === "he"
        ? "תנאי השימוש - כל המידע החשוב על קניות, משלוחים, החזרות, והגנת על פרטיות"
        : "Terms of Use - All the important information about purchases, shipping, returns, and privacy protection."
        
    const canonicalUrl = lang === "he"
        ? `https://ms-sugar.com/he/terms/${section || 'introduction'}/`
        : `https://ms-sugar.com/terms/${section || 'introduction'}/`

    /* JSX */
    return (
        <>
            <div className="page terms">
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
                {legalData?.termsAndConditions?.length > 0
                    ? <>
                        {media?.type === "mobile"
                            ? <MTerms/>
                            : <DTerms/>
                        }
                    </>
                    : <LoadingCover theme={"light"}/> 
                }
            </div>
        </>
    )
}

export default Terms