import React, { useRef, useEffect, useState } from "react"
import "./m-p-video.scss"

const MPVideo = ({ item }) => {

    /* Locale */
    const [src, setSrc] = useState(null)
    const videoRef = useRef(null)

    /* Triggers */
    useEffect(() => {
        updateSrc()
    }, [item])

    useEffect(() => {
        if (src) {
            const videoElement = videoRef.current
            videoElement.src = src
            videoElement.load()
        }
    }, [src])

    /* Functions */
    const updateSrc = () => {
        const url = item?.sources?.[0]?.url?.replace("ms-sugar.com", "ms-sugar-Skateshop.myshopify.com")
        url ? setSrc(url) : setSrc(null)
    }

    const handleVideoClick = () => {
        if (videoRef.current.paused) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }

    /* JSX */
    return (
        <div
            className="m-p-video-container"
            onClick={handleVideoClick}>
            <video
                ref={videoRef}
                className="d-p-video"
                autoPlay 
                muted 
                loop 
                playsInline>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default MPVideo