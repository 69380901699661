import React from "react"
import "./d-p-t-v-cover.scss"

const DPTVCover = () => {

    /* JSX */
    return (
        <div className="d-p-t-v-cover-container">
            <span className="material-symbols-outlined">videocam</span>
        </div>
    )
}

export default DPTVCover