import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation } from 'react-router-dom'
import { ShopifyContext } from "../../../../context/shopify"
import { getCurrentPage, getTotalItems } from "../../../../lib/helpers"
import { useTranslation } from "../../../../hooks/useTranslation"
import "./navigation.scss"
import { LanguageContext } from "../../../../context/language"

const Navigation = () => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { cart } = useContext(ShopifyContext)
    const { t } = useTranslation()
    const location = useLocation()

    /* Locale */
    const [items, setItems] = useState(0)

    /* Triggers */
    useEffect(() => {
        updateItems()
    }, [cart?.lineItems])

    /* Functions */
    const updateItems = () => {
        const lineItems = getTotalItems({ lineItems: cart?.lineItems })
        setItems(lineItems)
    }

    const currentPage = getCurrentPage({ location })

    /* JSX */
    return (
        <nav className="desktop">
            <ul>
                <li className={currentPage === "mission" ? "active" : ""}>
                    <Link
                        to={lang === "he" ? "/he/mission/" : "/mission/"}
                        aria-label={t("navigation.mission.aria_label")}>
                            {t("navigation.mission.title")}</Link></li>
                <li className={currentPage === "exchange" ? "active" : ""}>
                    <Link
                        to={lang === "he" ? "/he/exchange/" : "/exchange/"}
                        aria-label={t("navigation.exchange.aria_label")}>
                            {t("navigation.exchange.title")}</Link></li>
                <li className={currentPage === "skateshop" ? "active" : ""}>
                    <Link
                        to={lang === "he" ? "/he/skateshop/" : "/skateshop/"}
                        aria-label={t("navigation.skateshop.aria_label")}>
                            <span className="material-symbols-outlined">storefront</span>
                            {t("navigation.skateshop.title")}</Link></li>
                <li className={currentPage === "cart" ? "active" : ""}>
                    <Link
                        to={lang === "he" ? "/he/cart/" : "/cart/"}
                        aria-label={t("navigation.cart.aria_label")}>
                            <span className="material-symbols-outlined flip-on-rtl">shopping_cart</span>
                            {t("navigation.cart.title")}
                            {items > 0 ? ` (${items})` : ""}</Link></li>
            </ul>
        </nav>
    )
}

export default Navigation