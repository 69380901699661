import React from "react"
import "./c-c-loading.scss"

const CCLoading = ({ loading }) => {

    /* JSX */
    return (
        <div className={"c-c-loading-container " + (loading ? "active" : "")}/>
    )
}

export default CCLoading