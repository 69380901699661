import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { LanguageContext } from "../../context/language"
import { goToPageTop } from "../../lib/helpers"
import ContactTitle from "./contact-title/contact-title"
import ContactForm from "./contact-form/contact-form"
import MetaTags from "../../components/meta-tags/meta-tags"
import "./contact.scss"

const Mission = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    
    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
    }, [location])

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / צור קשר`
        : `Ms-Sugar Skateshop / Contact`

    const description = lang === "he"
        ? "צרו קשר - שלחו לנו הודעה, שאלה או הצעה. אנחנו כאן כדי לעזור!"
        : "Contact Us - Send us a message, question, or suggestion. We're here to help!"

    const canonicalUrl = lang === "he"
        ? "https://ms-sugar.com/he/contact/"
        : "https://ms-sugar.com/contact/"

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page contact">
                <ContactTitle/>
                <ContactForm/>
            </div>
        </>
    )
}

export default Mission