import React, { useContext, useEffect, useState } from "react"
import DPBack from "./d-p-back/d-p-back"
import DPThombnails from "./d-p-thumbnails/d-p-thumbnails"
import DPMedia from "./d-p-media/d-p-media"
import { ShopifyContext } from "../../../../../context/shopify"
import "./d-p-gallery.scss"

const DPGallery = () => {
 
    /* Global */
    const { storeDisplay } = useContext(ShopifyContext)

    /* Locale */
    const [activeMedia, setActiveMedia] = useState(storeDisplay?.product?.productType === "decks" ? 1 : 0)

    /* Triggers */
    useEffect(() => {
        updateActiveMedia()
    }, [storeDisplay?.product?.id])

    /* Functions */
    const updateActiveMedia = () => {
        setActiveMedia(storeDisplay?.product?.productType === "decks" ? 1 : 0)
    }

    /* JSX */
    return (
        <div className="d-p-gallery-container">
            <div className="d-p-sticky-container">
                <div className="d-p-gallery-top-container">
                    <DPBack/>
                </div>
                <div className="d-p-gallery-inner-container">
                    <DPThombnails
                        media={storeDisplay?.product?.media}
                        setActiveMedia={setActiveMedia}
                        activeMedia={activeMedia}/>
                    <DPMedia
                        media={storeDisplay?.product?.media}
                        activeMedia={activeMedia}/>
                </div>
            </div>
        </div>
    )
}

export default DPGallery