import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LanguageContext } from "../../../../../../context/language"
import { useTranslation } from "../../../../../../hooks/useTranslation"
import DPRSImage from "./d-p-r-s-image/d-p-r-s-image"
import "./d-p-releted-slide.scss"

const DPRelatedPSlide = ({ product }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { t, fixForTranslation } = useTranslation()
    const navigate = useNavigate()

    /* Locale */
    const [img, setImg] = useState({})

    /* Triggers */
    useEffect(() => {
        updateImg()
    }, [product])

    /* Functions */
    const updateImg = () => {
        setImg(product?.media?.[0] || {})
    }

    const handleClick = () => {
        if (product.availableForSale) {
            let variant = product?.variants?.find(variant => variant?.availableForSale)
            if (lang === "he") {
                navigate(`/he/product/${product?.idNumber}/${variant?.idNumber}`)
            } else {
                navigate(`/product/${product?.idNumber}/${variant?.idNumber}`)
            }
        }
    }
    
    const fixTitle = fixForTranslation({ string: product?.title })

    /* JSX */
    return (
        <button
            onClick={handleClick}
            className="d-p-releted-slide-container"
            aria-label={product.availableForSale ? `View details for ${product?.title}` : `Product ${product?.title} is not available`}
            disabled={!product.availableForSale}
        >
            <div className="d-p-releted-slide-img-container">
                { (img?.mediaContentType === "IMAGE") && <DPRSImage item={img}/> }
            </div>
            <div className="d-p-releted-slide-bottom-container">
                <p>
                    {lang === "en"
                        ? product?.title
                        : t(`pages.skateshop.products.list.${fixTitle}.title`)}
                </p>
            </div>
        </button>
    )
}

export default DPRelatedPSlide