import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { goToPageTop } from "../../lib/helpers"
import { useMedia } from "../../hooks/useMedia"
import MetaTags from "../../components/meta-tags/meta-tags"
import { LanguageContext } from "../../context/language"
import MCart from "./m-cart/m-cart"
import DCart from "./d-cart/d-cart"
import "./cart.scss"

const Cart = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { media } = useMedia()
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    

    /* Local */
    const [trigerDelete, setTrigerDelete] = useState(-1)
    const [loading, setLoading] = useState(-1)

    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
    }, [location])

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / עגלת קניות`
        : `Ms-Sugar Skateshop / Cart`

    const description = lang === "he"
        ? "ראו את כל המוצרים שבחרתם וסיימו את הקנייה בקלות"
        : "View all your selected items and easily complete your purchase"
        
    const canonicalUrl = lang === "he"
        ? "https://ms-sugar.com/he/cart/"
        : "https://ms-sugar.com/cart/"

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page cart">
                <div className="inner-cart-container">
                    { media.type === "mobile"
                        ? <MCart
                            loading={loading}
                            setLoading={setLoading}
                            trigerDelete={trigerDelete}
                            setTrigerDelete={setTrigerDelete}/>
                        : <DCart
                            loading={loading}
                            setLoading={setLoading}
                            trigerDelete={trigerDelete}
                            setTrigerDelete={setTrigerDelete}/>
                    }
                </div>
            </div>
        </>
    )
}

export default Cart