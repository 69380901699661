import React from "react"
import "./q.scss"

const Q = ({ item }) => {

    /* JSX */
    return (
        <div
            className="q-container"
            dangerouslySetInnerHTML={{__html: item?.questionAsHTML}}/>
    )
}

export default Q