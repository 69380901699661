import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

const OrderRedirect = () => {

    /* Global */
    const { store_id, order_id } = useParams()

    /* Triggers */
    useEffect(() => {
        redirectOrder()
    }, [store_id, order_id])

    /* Functions */
    const redirectOrder = () => {
        if (store_id && order_id) {
            window.location.href = `https://shopify.com/${store_id}/account/orders/${order_id}?locale=en`
        }
    }
}

export default OrderRedirect