import React, { useContext, useEffect } from "react"
import { LanguageContext } from "../../context/language"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { goToPageTop } from "../../lib/helpers"
import EFormSection from "./e-form-section/e-form-section"
import MetaTags from "../../components/meta-tags/meta-tags"
import "./exchange.scss"

const Exchange = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
    }, [location])

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / החלפות`
        : `Ms-Sugar Skateshop / Exchange`

    const description = lang === "he"
        ? "שילחו סיפור, או שיר, או כל צורה אחרת של חיבור מילים, בתמורה ל-10% הנחה."
        : "Send a story, a poem, or any form of text in exchange for a 10% discount"

    const canonicalUrl = lang === "he"
        ? "https://ms-sugar.com/he/exchange/"
        : "https://ms-sugar.com/exchange/"

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page exchange">
                <EFormSection/>
            </div>
        </>
    )
}

export default Exchange