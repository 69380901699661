import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { LanguageContext } from "../../context/language"
import { ShopifyContext } from "../../context/shopify"
import { useMedia } from "../../hooks/useMedia"
import { goToPageTop } from "../../lib/helpers"
import MetaTags from "../../components/meta-tags/meta-tags"
import MProd from "./m-prod/m-prod"
import DProd from "./d-prod/d-prod"
import "./product.scss"

const Product = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { updateProductDisplay, storeDisplay, loadingStore } = useContext(ShopifyContext)
    const { media } = useMedia()
    const { productId, variantId } = useParams()
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
   
    /* Locale */
    const [notifyWhenAvailable, setNotifyWhenAvailable] = useState(false)

    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
        updateProduct()
    }, [location, loadingStore, storeDisplay?.product])
 
    /* Functions */
    const updateProduct = () => {
        updateProductDisplay({ productId, variantId })
    }

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / ${storeDisplay?.product?.title || ""}`
        : `Ms-Sugar Skateshop / ${storeDisplay?.product?.title || ""}`

    const description = lang === "he"
        ? "מותג סקייטבורד ישראלי! קרשים לסקייטבורד בעיצוב ייחודי ומעורר השראה, באיכות הגבוהה ביותר."
        : "An Israeli Skateboard brand! Skateboard decks with unique and inspiring design, in the best professional quality."

    const canonicalUrl = lang === "he"
        ? `https://ms-sugar.com/he/product/${storeDisplay?.product?.idNumber}/`
        : `https://ms-sugar.com/product/${storeDisplay?.product?.idNumber}/`

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page product">
                {media?.type === "mobile"
                    ? <MProd
                        notifyWhenAvailable={notifyWhenAvailable}
                        setNotifyWhenAvailable={setNotifyWhenAvailable}/>
                    : <DProd
                        notifyWhenAvailable={notifyWhenAvailable}
                        setNotifyWhenAvailable={setNotifyWhenAvailable}/>
                }
            </div>
        </>
    )
}

export default Product