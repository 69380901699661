import React from "react"
import { Img } from "react-image"
import "./m-p-image.scss"

const MPImage = ({ item }) => {

    /* JSX */
    return (
        <Img
            className="m-p-image"
            src={item?.image?.originalSrc}
            alt={item?.alt}/>
    )
}

export default MPImage