import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { LanguageContext } from "../../../../../context/language"
import { ShopifyContext } from "../../../../../context/shopify"
import { getTotalItems, getCurrentPage } from "../../../../../lib/helpers"
import { useTranslation } from "../../../../../hooks/useTranslation"
import "./desktop-footer-top-center.scss"

const DesktopFooterTopCenter = () => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { cart } = useContext(ShopifyContext)
    const { t } = useTranslation()
    const location = useLocation()
 
    /* Locale */
    const [items, setItems] = useState(0)

    /* Triggers */
    useEffect(() => {
        updateItems()
    }, [cart?.lineItems])

    /* Functions */
    const updateItems = () => {
        const lignItems = getTotalItems({ lineItems: cart?.lineItems })
        setItems(lignItems)
    }

    const currentPage = getCurrentPage({ location })

    /* JSX */
    return (
        <nav className="desktop-footer-top-center-container">
            <ul>
                <li>
                    <p>{t("footer.info.title")}</p>
                    <div className={"inner-li-container " + (currentPage === "mission" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/mission/" : "/mission/"}
                            aria-label={t("navigation.mission.aria_label")}>
                                {t("navigation.mission.title")}</Link></div>
                    <div className={"inner-li-container " + (currentPage === "faq" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/faq/skateshop/" : "/faq/skateshop/"}
                            aria-label={t("navigation.faq.aria_label")}>
                                {t("navigation.faq.title")}</Link></div>
                    <div className={"inner-li-container " + (currentPage === "contact" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/contact/" : "/contact/"}
                            aria-label={t("navigation.contact.aria_label")}>
                                {t("navigation.contact.title")}</Link></div>
                </li>
                <li>
                    <p>{t("footer.market.title")}</p>
                    <div className={"inner-li-container " + (currentPage === "exchange" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/exchange/" : "/exchange/"}
                            aria-label={t("navigation.exchange.aria_label")}>
                                {t("navigation.exchange.title")}</Link></div>
                    <div className={"inner-li-container " + (currentPage === "skateshop" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/skateshop/" : "/skateshop/"}
                            aria-label={t("navigation.skateshop.aria_label")}>
                                <span className="material-symbols-outlined">storefront</span>
                                {t("navigation.skateshop.title")}</Link></div>
                    <div className={"inner-li-container " + (currentPage === "cart" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/cart/" : "/cart/"}
                            aria-label={t("navigation.cart.aria_label")}>
                                <span className="material-symbols-outlined flip-on-rtl">shopping_cart</span>
                                {t("navigation.cart.title")}{items > 0 ? ` (${items})` : ""}</Link></div>
                </li>
                <li>
                    <p>{t("footer.legal.title")}</p>
                    <div className={"inner-li-container " + (currentPage === "terms" ? "active" : "")}>
                        <Link
                            to={lang === "he" ? "/he/terms/" : "/terms/"}
                            aria-label={t("navigation.terms.aria_label")}>
                                {t("navigation.terms.title")}</Link></div>
                </li>
                <li>
                    <p>{t("footer.social.title")}</p>
                    <div className="inner-li-container">
                        <Link
                            to="https://x.com/MsSugarOfficial/"
                            target="_blank"
                            aria-label={t("navigation.x.aria_label")}
                            rel="noopener noreferrer">{t("navigation.x.title")}</Link></div>
                    <div className="inner-li-container">
                        <Link
                            to="https://www.instagram.com/mssugarofficial/"
                            target="_blank"
                            aria-label={t("navigation.instagram.aria_label")}
                            rel="noopener noreferrer">{t("navigation.instagram.title")}</Link></div>
                    <div className="inner-li-container">
                        <Link
                            to="https://www.facebook.com/profile.php?id=61571233436003"
                            target="_blank"
                            aria-label={t("navigation.facebook.aria_label")}
                            rel="noopener noreferrer">{t("navigation.facebook.title")}</Link></div>
                </li>
            </ul>
        </nav>
    )
}

export default DesktopFooterTopCenter