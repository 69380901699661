import React, { useContext } from "react"
import { GlobalContext } from "../../../../context/global"
import { LanguageContext } from "../../../../context/language"
import { useTranslation } from "../../../../hooks/useTranslation"
import LIRadio from "./l-i-radio/l-i-radio"
import LITitle from "./l-i-title/l-i-title"
import "./lang-item.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"

const LangItem = ({ lang }) => {

    /* Global */
    const { setIsSideMenuOpen } = useContext(GlobalContext)
    const { updateLang, setOpenLangPopup } = useContext(LanguageContext)
    const { t } = useTranslation()
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    /* Functions */
    // const handleClick = () => {
    //     updateLang({ lang: lang.code })
    //     setOpenLangPopup(false)
    //     setIsSideMenuOpen(false)
    // }

    const handleClick = () => {
        const currentPath = location.pathname
        const isHebrew = currentPath.startsWith("/he")

        updateLang({ lang: lang.code })
        setOpenLangPopup(false)
        setIsSideMenuOpen(false)

        if (lang.code === "en" && isHebrew) {
            const cleanPath = currentPath.replace("/he", "")
            navigate(cleanPath || "/", { replace: true })
        } else if (lang.code === "he" && !isHebrew) {
            const newPath = `/he${currentPath === "/" ? "" : currentPath}`
            navigate(newPath, { replace: true })
        }
    }

    /* JSX */
    return (
        <li
            aria-label={t(`language.popup.change_to_${lang.code}.aria_label`)}
            className="lang-item-container"
            onClick={handleClick}>
            <LIRadio langCode={lang.code}/>
            <lang.component/>
            <LITitle langCode={lang.code} title={lang.title}/>
        </li>
    )
}

export default LangItem