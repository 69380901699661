import React, { useState, useEffect, useRef } from "react"
import DPThomdnail from "./d-p-thombnail/d-p-thombnail"
import DPTButtonUp from "./d-p-t-button-up/d-p-t-button-up"
import DPTButtonDown from "./d-p-t-button-down/d-p-t-button-down"
import "./d-p-thumbnails.scss"

const DPThombnails = ({ media, setActiveMedia, activeMedia }) => {

    /* Locale */
    const [isOverflowingUp, setIsOverflowingUp] = useState(false)
    const [isOverflowingDown, setIsOverflowingDown] = useState(false)
    const containerRef = useRef(null)
    const scrollIntervalRef = useRef(null)

    /* Triggers */
    useEffect(() => {
        checkOverflow()
        const el = containerRef.current
        if (el) {
            el.addEventListener('scroll', checkOverflow)
            window.addEventListener('resize', checkOverflow)
        }
        return () => {
            if (el) {
                el.removeEventListener('scroll', checkOverflow)
                window.removeEventListener('resize', checkOverflow)
            }
        }
    }, [media])

    /* Functions */
    const checkOverflow = () => {
        const el = containerRef.current
        if (el) {
            const isOverflowingUp = el.scrollTop > 0
            const isOverflowingDown = el.scrollHeight > el.offsetHeight + el.scrollTop

            setIsOverflowingUp(isOverflowingUp)
            setIsOverflowingDown(isOverflowingDown)
        }
    }

    const scrollUp = () => {
        const el = containerRef.current;
        if (el) {
            el.scrollBy({ top: -20, behavior: 'auto' })
        }
    }

    const scrollDown = () => {
        const el = containerRef.current;
        if (el) {
            el.scrollBy({ top: 20, behavior: 'auto' })
        }
    }

    const startScroll = (direction) => {
        if (scrollIntervalRef.current === null) {
            scrollIntervalRef.current = setInterval(() => {
                if (direction === "up") {
                    scrollUp()
                } else if (direction === "down") {
                    scrollDown()
                }
            }, 50)
        }
    }

    const stopScroll = () => {
        if (scrollIntervalRef.current !== null) {
            clearInterval(scrollIntervalRef.current)
            scrollIntervalRef.current = null
        }
    }

    /* JSX */
    return (
        <div className="d-p-thumbnails-container" ref={containerRef}>
            <DPTButtonUp
                isOverflowingUp={isOverflowingUp}
                startScroll={startScroll}
                stopScroll={stopScroll}/>
            <div className="d-p-thumbnails-inner-container">
                {media?.map((media, i) => {
                    return <DPThomdnail
                    key={i}
                    mediaIndex={i}
                    media={media}
                    setActiveMedia={setActiveMedia}
                    activeMediae={activeMedia}/>
                })}
            </div>
            <DPTButtonDown
                isOverflowingDown={isOverflowingDown}
                startScroll={startScroll}
                stopScroll={stopScroll}/>
        </div>
    )
}

export default DPThombnails