import React, { useContext, useEffect, useRef, useState } from "react"
import { ShopifyContext } from "../../../../../context/shopify"
import { LanguageContext } from "../../../../../context/language"
import PShare from "../../../parts/p-share/p-share"
import MPMedia from "./m-p-media/m-p-media"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./m-p-gallery.scss"

const MPGallery = () => {

    /* Global */
    const { storeDisplay } = useContext(ShopifyContext)
    const { lang } = useContext(LanguageContext)

    /* Locale */
    const [imageCount, setImageCount] = useState(storeDisplay?.product?.images?.length || 0)
    const [media, setMedia] = useState([])
    const sliderRef = useRef(null)

    const settings = {
        dots: true,
        infinite: imageCount > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        rtl: false
    }

    const galleryStyle = {
        '--number-of-dots': storeDisplay?.product?.media?.length
    }

    /* Triggers */
    useEffect(() => {
        updateSettings()
        resetSlider()
        updateMedia()
    }, [storeDisplay?.product?.id])
    
    /* Functions */
    const updateMedia = () => {
        setMedia(() => {
            return storeDisplay?.product?.media
            // return lang === "he"
            //     ? storeDisplay?.product?.media?.slice().reverse() // Create a new array and reverse it
            //     : storeDisplay?.product?.media
        })
    }
    const resetSlider = () => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(storeDisplay?.product?.productType === "decks" ? 1 : 0)
        }
    }
    const updateSettings = () => {
        setImageCount(storeDisplay?.product?.media?.length || 0)
    }

    /* JSX */
    if (media?.length > 0) {
        return (
            <div className="m-p-gallery-container" style={galleryStyle}>
                <PShare/>
                <Slider {...settings} ref={sliderRef} dir="ltr">
                    {storeDisplay?.product?.media?.map((media, i) => {
        
                        return <MPMedia key={i} media={media} />
                    })}
                </Slider>
            </div>
        )
    }
}

export default MPGallery