import React from "react"
import { Img } from "react-image"
import "./d-p-image.scss"

const DPImage = ({ item }) => {

    /* JSX */
    return (
        <Img
            className="d-p-image"
            src={item?.image?.originalSrc}
            alt={item?.alt}/>
    )
}

export default DPImage