import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import "./m-faq-cat-item.scss"

const MFAQCatItem = ({ item, setSelectedIndex }) => {

    /* Global */
    const { category } = useParams()

    /* Triggers */
    useEffect(() => {
        resetSelectedIndex()
    }, [category])


    /* Functions */
    const resetSelectedIndex = () => {
        setSelectedIndex(-1)
    }

    /* JSX */
    return (
        <li
            className={"m-faq-cat-item-container " + (category === item?.slug ? "selected" : "")}>
            <Link
                to={`/faq/${item?.slug}`}
                aria-label={item?.value}>
                {`${item?.value} ` + `(${item?.faqList?.length})`}
            </Link>
        </li>
    )
}

export default MFAQCatItem