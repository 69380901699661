import React, { useState, useEffect } from "react";
import DPImage from "./d-p-image/d-p-image";
import DPVideo from "./d-p-video/d-p-video";
import LoadingCover from "../../../../../../components/loading-cover/loading-cover";
import "./d-p-media.scss";

const DPMedia = ({ media, activeMedia }) => {

    const [item, setItem] = useState(null);
    const [type, setType] = useState(null);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        setFade(true); // Start fade-out
        const fadeTimeout = setTimeout(() => {
            const newItem = media?.[activeMedia];
            setItem(newItem);
            setType(newItem?.mediaContentType);
            setFade(false); // Start fade-in
        }, 300); // Duration of fade-out

        return () => clearTimeout(fadeTimeout);
    }, [media, activeMedia]);

    const isMedia = media?.length > 0;

    return (
        <div className={`d-p-media-container ${fade ? "fade" : ""}`}>
            {item ? (
                <>
                    {isMedia && type === "IMAGE" && <DPImage item={item} />}
                    {isMedia && type === "VIDEO" && <DPVideo item={item} />}
                </>
            ) : (
                <LoadingCover theme={"light"} />
            )}
        </div>
    );
};

export default DPMedia;
