import React, { useContext, useEffect, useState } from "react"
import { CurrencyContext } from "../../../../context/currency"
import { formatPrice } from "../../../../lib/helpers"
import "./c-item-price.scss"

const CItemPrice = ({ item }) => {

    /* Global */
    const { currency, getConversion, conversionRates } = useContext(CurrencyContext)

    /* Locale */
    const [amount, setAmount] = useState(null)

    /* Triggers */
    useEffect(() => {
        updateAmount()
    }, [currency, item.quantity, conversionRates])

    /* Functions */
    const updateAmount = async () => {
        if (item?.variant?.price?.amount) {
            const amountOfOne = Number(item?.variant?.price?.amount)
            const convertedAmount = await getConversion({ to: currency, amount: amountOfOne * item.quantity })
            setAmount(convertedAmount)
        }
    }

    /* JSX */
    return (
        <div className="c-item-price-container">
            <p>{formatPrice(amount, currency)}</p>
        </div>
    )
}

export default CItemPrice