import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from "react-helmet-async"
import App from './app/app'
// import Accessibility from './components/accessibility/accessibility'
import { LanguageProvider } from "./context/language"  
import { GlobalProvider } from './context/global'
import { ShopifyProvider } from './context/shopify'
// import { AccessibilityProvider } from './context/accessibility'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { CurrencyProvider } from './context/currency'

const root = ReactDOM.createRoot(document.getElementById('root'))

const helmetContext = {}
root.render(
    // <GoogleReCaptchaProvider
    //     reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <LanguageProvider>
            <GlobalProvider>
                <ShopifyProvider>
                    <CurrencyProvider>
        {/* <AccessibilityProvider>
            <Accessibility/> */}
                    <HelmetProvider context={helmetContext}>
                        <App/>
                    </HelmetProvider>
            {/* </AccessibilityProvider> */}
                    </CurrencyProvider>
                </ShopifyProvider>
            </GlobalProvider>
        </LanguageProvider>
    // </GoogleReCaptchaProvider>
)