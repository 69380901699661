import React, { useContext, useState } from "react"
import { ShopifyContext } from "../../../../context/shopify"
import "./c-item-quantity.scss"

const CItemQuantity = ({ item, setTrigerDelete, index }) => {

    /* Global */
    const { addToCart, updateCartQuantity } = useContext(ShopifyContext)

    /* Locale */
    const [loading, setLoading] = useState(false)
    const [clickButton, setClickButton] = useState("")

    /* Functions */
    const decreaseQuantity = async () => {
        if (item?.quantity === 1) {
            setTrigerDelete(index)
        } else if (!loading) {
            setLoading(true)
            setClickButton("minus")
            await updateCartQuantity({
                variantId: item?.id,
                quantity: item?.quantity - 1
            })
            setLoading(false)
            setClickButton("")
        }
    }

    const increaseQuantity = async () => {
        if (!loading) {
            setLoading(true)
            setClickButton("plus")
            await addToCart({ 
                variantId: item?.variant?.id,
                quantity: 1 })
            setLoading(false)
            setClickButton("")
        }
    }

    /* JSX */
    return (
        <div className={"c-item-quantity-container " + (loading ? "loading" : "")}>
            <div className="c-item-quantity-inner-container">
                <button
                    className={"quantity-button " + (clickButton === "minus" ? "active" : "")}
                    onClick={decreaseQuantity}
                    aria-label="Decrease quantity">
                    <span className="material-symbols-outlined">remove</span>
                </button>
                <div className="quantity-total">
                    <p>{item?.quantity}</p>
                </div>
                <button
                    className={"quantity-button " + (clickButton === "plus" ? "active" : "")}
                    onClick={increaseQuantity}
                    aria-label="Increase quantity">
                    <span className="material-symbols-outlined">add</span>
                </button>
            </div>
        </div>
    )
}

export default CItemQuantity