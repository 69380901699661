import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import LangPopup from "../components/lang-popup/lang-popup"
import Dialog from "./dialog/dialog"
import Header from "../components/header/header"
import Mission from './mission/mission'
import Exchange from "./exchange/exchange"
import Skateshop from "./skateshop/skateshop"
import Product from "./product/product"
import Cart from "./cart/cart"
import OrderRedirect from "./order-redirect/order-redirect"
import Contact from "./contact/contact"
import Faq from "./faq/faq"
import TermsAndConditions from "./terms/terms"
import NotFound from './not-found/not-found'
import Footer from "../components/footer/footer"
import "./app.scss"

const App = () => {

    /* Global */
    const location = useLocation()

    /* Triggers */
    useEffect(() => {
        // If there are query parameters in the URL, remove them
        if (window.location.search) {
            window.history.replaceState({}, document.title, window.location.pathname)
        }
    }, [location])

    /* JSX */
    return (
        <>
            <LangPopup/>
            <Dialog/>
            <Header/>
            <Routes>
                <Route path="/" element={<Mission/>}/>
                <Route path="/mission/" element={<Mission/>}/>
                <Route path="/exchange/" element={<Exchange/>}/>
                <Route path="/skateshop/" element={<Skateshop/>}/>
                <Route path="/product/" element={<Navigate to="/skateshop/" replace/>}/>
                <Route path="/product/:productId/:variantId/" element={<Product/>}/>
                <Route path="/product/:productId/" element={<Product/>}/>
                <Route path="/cart/" element={<Cart/>}/>
                <Route path="/cart/:data" element={<Navigate to="/cart/" replace/>}/>
                <Route path="/:store_id/orders/:order_id/authenticate" element={<OrderRedirect/>}/>
                <Route path="/contact/" element={<Contact/>}/>
                <Route path="/faq/" element={<Faq/>}/>
                <Route path="/faq/:category/" element={<Faq/>}/>
                <Route path="/terms/" element={<Navigate to="/terms/introduction/" replace/>}/>
                <Route path="/terms/:section" element={<TermsAndConditions/>}/>

                {/* Language-specific routes */}
                <Route path="/:urllang" element={<Mission />} />
                <Route path="/:urllang/mission" element={<Mission />} />
                <Route path="/:urllang/exchange" element={<Exchange />} />
                <Route path="/:urllang/skateshop" element={<Skateshop />} />
                <Route path="/:urllang/product" element={<Navigate to={`/:urllang/skateshop`} replace />} />
                <Route path="/:urllang/product/:productId/:variantId" element={<Product />} />
                <Route path="/:urllang/product/:productId" element={<Product />} />
                <Route path="/:urllang/cart" element={<Cart />} />
                <Route path="/:urllang/cart/:data" element={<Navigate to={`/:urllang/cart`} replace />} />
                <Route path="/:urllang/contact" element={<Contact />} />
                <Route path="/:urllang/faq" element={<Faq />} />
                <Route path="/:urllang/faq/:category" element={<Faq />} />
                <Route path="/:urllang/terms" element={<Navigate to={`/:urllang/terms/introduction`} replace />} />
                <Route path="/:urllang/terms/:section" element={<TermsAndConditions />} />

                {/* 404 */}
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer/>
        </>
    )
}

export default function AppWrapper() {
    return (
        <Router>
            <App/>
        </Router>
    )
}
