import React from "react"

const En = () => {

    /* JSX */
    return (
        <svg viewBox="0 0 60 39" fill="none" style={{height: "100%"}}>
            <path d="M60 0H0V39H60V0Z" fill="white"/>
            <path d="M60 36H0V39H60V36Z" fill="#D82727"/>
            <path d="M60 30H0V33H60V30Z" fill="#D82727"/>
            <path d="M60 24H0V27H60V24Z" fill="#D82727"/>
            <path d="M60 18H0V21H60V18Z" fill="#D82727"/>
            <path d="M60 12H0V15H60V12Z" fill="#D82727"/>
            <path d="M60 6H0V9H60V6Z" fill="#D82727"/>
            <path d="M60 0H0V3H60V0Z" fill="#D82727"/>
            <path d="M26 0H0V21H26V0Z" fill="#0038B8"/>
            <path d="M4.99 5.17L3.75 5.82L3.98 4.44L2.98 3.46L4.37 3.26L4.99 2L5.61 3.26L7 3.46L5.99 4.44L6.23 5.82L4.99 5.17Z" fill="white"/>
            <path d="M4.99 11.26L3.75 11.91L3.98 10.53L2.98 9.55L4.37 9.35L4.99 8.09L5.61 9.35L7 9.55L5.99 10.53L6.23 11.91L4.99 11.26Z" fill="white"/>
            <path d="M4.99 17.35L3.75 18L3.98 16.62L2.98 15.64L4.37 15.43L4.99 14.18L5.61 15.43L7 15.64L5.99 16.62L6.23 18L4.99 17.35Z" fill="white"/>
            <path d="M20.99 5.17L19.75 5.82L19.98 4.44L18.98 3.46L20.37 3.26L20.99 2L21.61 3.26L23 3.46L21.99 4.44L22.23 5.82L20.99 5.17Z" fill="white"/>
            <path d="M20.99 11.26L19.75 11.91L19.98 10.53L18.98 9.55L20.37 9.35L20.99 8.09L21.61 9.35L23 9.55L21.99 10.53L22.23 11.91L20.99 11.26Z" fill="white"/>
            <path d="M20.99 17.35L19.75 18L19.98 16.62L18.98 15.64L20.37 15.43L20.99 14.18L21.61 15.43L23 15.64L21.99 16.62L22.23 18L20.99 17.35Z" fill="white"/>
            <path d="M12.99 5.17L11.75 5.82L11.98 4.44L10.98 3.46L12.37 3.26L12.99 2L13.61 3.26L15 3.46L13.99 4.44L14.23 5.82L12.99 5.17Z" fill="white"/>
            <path d="M12.99 11.26L11.75 11.91L11.98 10.53L10.98 9.55L12.37 9.35L12.99 8.09L13.61 9.35L15 9.55L13.99 10.53L14.23 11.91L12.99 11.26Z" fill="white"/>
            <path d="M12.99 17.35L11.75 18L11.98 16.62L10.98 15.64L12.37 15.43L12.99 14.18L13.61 15.43L15 15.64L13.99 16.62L14.23 18L12.99 17.35Z" fill="white"/>
        </svg>
    )
}

export default En