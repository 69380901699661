import React, { useContext, useEffect, useState } from "react"
import { ShopifyContext } from "../../../../../context/shopify"
import { CurrencyContext } from "../../../../../context/currency"
import { formatPrice } from "../../../../../lib/helpers"
import { useTranslation } from "../../../../../hooks/useTranslation"
import CSSChangeCurrency from "./c-s-s-change-currency/c-s-s-change-currency"
import "./c-sum-subtotal.scss"

const CSumSubTotal = () => {

    /* Global */
    const { cart } = useContext(ShopifyContext)
    const { currency, getConversion, conversionRates } = useContext(CurrencyContext)
    const { t } = useTranslation()

    /* Locale */
    const [listOpen, setListOpen] = useState(false)
    const [amount, setAmount] = useState(null)

    /* Triggers */
    useEffect(() => {
        updateAmount()
    }, [currency, cart?.subtotalPrice?.amount, conversionRates])

    /* Functions */
    const updateAmount = async () => {
        if (cart?.subtotalPrice?.amount) {
            const convertedAmount = await getConversion({ to: currency, amount: cart?.subtotalPrice?.amount })
            setAmount(convertedAmount)
        }
    }

    /* JSX */
    return (
        <div className="c-sum-subtotal-container">
            <p>{t("pages.cart.sub_total_title")}</p>
            <div className="c-sum-subtotal-inner-container">
                <h2>{formatPrice(amount, currency)}</h2>
                {/* { conversionRates?.[currency] && <CSSChangeCurrency
                                                    listOpen={listOpen}
                                                    setListOpen={setListOpen}/> } */}
            </div>
        </div>
    )
}

export default CSumSubTotal