import React, { useRef, useState, useEffect } from "react"
// import PVideoControl from "../../../../../parts/p-video-control/p-video-control"
import "./d-p-video.scss"

const DPVideo = ({ item }) => {

    /* Locale */
    const [src, setSrc] = useState(null)
    const [controlActive, setControlActive] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const videoRef = useRef(null)

    /* Triggers */
    useEffect(() => {
        updateSrc()
    }, [item])

    useEffect(() => {
        if (src) {
            const videoElement = videoRef.current
            videoElement.src = src
            videoElement.load()
        }
    }, [src])

    useEffect(() => {
        const video = videoRef.current

        const handleTimeUpdate = () => {
            setCurrentTime(video.currentTime)
        }
        const handleLoadedMetadata = () => {
            setDuration(video.duration)
        }
        video.addEventListener("timeupdate", handleTimeUpdate)
        video.addEventListener("loadedmetadata", handleLoadedMetadata)

        return () => {
            video.removeEventListener("timeupdate", handleTimeUpdate)
            video.removeEventListener("loadedmetadata", handleLoadedMetadata)
        }
    }, [])

    /* Functions */
    const updateSrc = () => {
        const url = item?.sources?.[0]?.url?.replace("ms-sugar.com", "ms-sugar-skateshop.myshopify.com")
        url ? setSrc(url) : setSrc(null)
    }

    const onTimeUpdate = (newTime) => {
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    }
    const handleVideoClick = (e) => {
        if (e.target.tagName !== 'VIDEO') {
            return
        }
        const video = videoRef?.current
        if (video) {
            if (video.paused) {
                handleDragEnd()
            } else {
                handleDragStart()
            }
        }
    }
    const handleMouseEnter = () => {
        setControlActive(true)
    }
    const handleMouseLeave = () => {
        setControlActive(false)
    }
    const handleDragStart = () => {
        videoRef?.current?.pause()
    }
    const handleDragEnd = () => {
        videoRef?.current?.play()
    }

    /* JSX */
    return (
        <div
            className="d-p-video-container"
            onClick={handleVideoClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <video
                ref={videoRef}
                className="d-p-video"
                autoPlay 
                muted 
                loop 
                playsInline>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* <PVideoControl
                videoRef={videoRef}
                active={controlActive}
                currentTime={currentTime}
                duration={duration}
                onTimeUpdate={onTimeUpdate}
                onDragStart={handleDragStart}/> */}
        </div>
    )
}

export default DPVideo