import React, { useContext, useEffect, useState } from "react"
import { ShopifyContext } from "../../../../../context/shopify"
import placeholder from "../../../../../assets/image-placeholder.svg"
import PPImage from "./p-p-image/p-p-image"
import "./p-preview-media.scss"

const PPreviewMedia = ({ product }) => {

    /* Global */
    const { storeDisplay } = useContext(ShopifyContext)

    /* Locale */
    const [media, setMedia] = useState({})

    /* triggers */
    useEffect(() => {
        updateMedia()
    }, [storeDisplay, product])

    /* Functions */
    const updateMedia = () => {
        if (product.media[0]) {
            setMedia(product.media[0])
        } else {
            setMedia({
                image: {originalSrc: placeholder},
                mediaContentType: "IMAGE",
                alt: "Skateshop image placeholder"
            })
        }
    }

    /* JSX */
    return (
        <div className="p-preview-media-container">
            { (media?.mediaContentType === "IMAGE") && <PPImage item={media}/> }
        </div>
    )
}

export default PPreviewMedia