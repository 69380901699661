import React from "react"
import { Img } from "react-image"
import "./d-p-t-image.scss"

const DPTImage = ({ item }) => {

    /* JSX */
    return (
        <Img 
            className="d-p-t-image"
            src={item?.image?.originalSrc}
            alt={item?.alt}/>
    )
}

export default DPTImage