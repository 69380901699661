import React, { createContext, useEffect, useState } from 'react'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
    
    /* Locale */
    const [lang, setLang] = useState(() => {
        const savedLang = localStorage.getItem('lang')
        const navigatorLanguge = (navigator.language === "he" || navigator.language === "he-IL") ? "he" : "en"
        return savedLang ? savedLang : navigatorLanguge
    })
    const [openLangPopup, setOpenLangPopup] = useState(false)

    /* Triggers */
    useEffect(() => {
        updateRTL()
    }, [lang])

    /* Functions */
    const updateRTL = () => {
        if (lang === "he") {
            document.body.setAttribute('dir', 'rtl');
        } else {
            document.body.setAttribute('dir', 'ltr');
        }
    }

    const updateLang = ({ lang }) => {
        localStorage.setItem('lang', lang)
        setLang(lang)
    }

    const updateUrlLang = ({ urllang, location, navigate }) => {
        if (urllang === "he") {
            updateLang({ lang: urllang })
        } else {
            if (urllang === "en") {
                updateLang({ lang: urllang })
                const cleanPath = location.pathname.replace(`/${urllang}`, '')
                navigate(cleanPath || '/', { replace: true })
                return
            }
            if (lang === "he") {
                const cleanPath = location.pathname.replace(`/${urllang}`, '/he')
                navigate(cleanPath || '/', { replace: true })
                return
            } 
            const cleanPath = location.pathname.replace(`/${urllang}`, '')
            navigate(cleanPath || '/', { replace: true })
        }
    }

    const payload = {
        lang,
        openLangPopup,
        setOpenLangPopup,
        updateUrlLang,
        updateLang
    }

    return (
        <LanguageContext.Provider value={payload}>
            {children}
        </LanguageContext.Provider>
    )
}