import React, { useEffect, useState, useContext } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from "../../context/global"
import { LanguageContext } from "../../context/language"
import { goToPageTop } from "../../lib/helpers"
import { useMedia } from "../../hooks/useMedia"
import MetaTags from "../../components/meta-tags/meta-tags"
import MFAQCategories from "./parts/m-faq-categories/m-faq-categories"
import DFAQCategories from "./parts/d-faq-categories/d-faq-categories"
import FAQSearch from "./faq-search/faq-search"
import FAQList from "./parts/faq-list/faq-list"
import LoadingCover from "../../components/loading-cover/loading-cover"
import "./faq.scss"

const Faq = () => {

    /* Global */
    const { faqData } = useContext(GlobalContext)
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { media } = useMedia()
    const { category, urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    /* Locale */
    const [string, setString] = useState("")
    const [categories, setCategories] = useState([])
    const [list, setList] = useState([])
    const [listByLang, setListByLang] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(-1)

    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])
    
    useEffect(() => {
        updateList()
    }, [faqData, lang, category, string])

    useEffect(() => {
        updateCategories()
    }, [list])

    useEffect(() => {
        goToPageTop()
    }, [location])

    /* Functions */
    const getFilterdLang = () => {
        if (lang) {
            return faqData.map(item => {
                const q = item[lang].questionAsHTML
                const a = item[lang].questionAsHTML
                return q && a ? item[lang] : null
            } 
        )
        } else {
            return []
        }
    }

    const getFilterdCatregory = ({ list }) => {
        if (category) {
            return category === "all"
                ? list
                : list?.filter(item => {
                    return item?.slug?.toLowerCase() === category?.toLowerCase()
                }) 
        } else {
            return list
        }
    }

    const getFilterdSearch = ({ list }) => {
        if (string) {
            const searchRegex = new RegExp(`(${string})`, 'gi')
            const filterdByString = list?.filter(item => {
                return item.questionAsHTML.toLowerCase().includes(string.toLowerCase()) || item.answerAsHTML.toLowerCase().includes(string.toLowerCase())
            })
            return filterdByString?.map(item => ({...item,
                questionAsHTML: item.questionAsHTML.replace(searchRegex, '<b>$1</b>'),
                answerAsHTML: item.answerAsHTML.replace(searchRegex, '<b>$1</b>')
            }))
        } else {
            return list
        }
    }

    const updateList = () => {
        const filterdByLang = getFilterdLang()
        const filterdByCategory = getFilterdCatregory({ list: filterdByLang })
        const filterdBySearch = getFilterdSearch({ list: filterdByCategory })
        setListByLang(filterdByLang)
        setList(filterdBySearch)
    }

    const getUniqueCategories = ({ items }) => {
        const categoryMap = new Map()
      
        items.forEach(item => {
            if (!categoryMap.has(item.slug)) {
                categoryMap.set(item.slug, { slug: item.slug, value: item.category })
            }
        })
      
        return Array.from(categoryMap.values())
    }
     
    const getWithList = ({ items }) => {
        return items.map(item => {
            const filterdByLang = getFilterdLang()

            const faqList = filterdByLang.filter(faq => {
                return faq.category?.toLowerCase() === item?.value?.toLowerCase()
            })

            return { ...item, faqList }
        })
    }

    const updateCategories = () => {
        const filterdByLang = getFilterdLang()
        const uniqeCategories = getUniqueCategories({ items: filterdByLang })
        const witeList = getWithList({ items: uniqeCategories })
        witeList.push({
            slug: "all",
            faqList: filterdByLang,
            value: (lang === "he" ? "הכל" : "all") 
        })
        setCategories(witeList)
    }

    const name = lang === "he"
        ? `חנות הסקייטבורד מיס-שוגר / שאלות`
        : `Ms-Sugar Skateshop / FAQ`

    const description = lang === "he"
        ? "כל התשובות לכל השאלות שמיס-שוגר יכולה לענות."
        : "All the answers for all questions Ms-Sugar can answer."

    const canonicalUrl = lang === "he"
        ? "https://ms-sugar.com/he/faq/"
        : "https://ms-sugar.com/faq/"

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page faq">
                <div className="faq-inner-container">
                    {listByLang?.length > 0
                            ? <>
                                { media?.type === "mobile" && <MFAQCategories
                                    setSelectedIndex={setSelectedIndex}
                                    listByLang={listByLang}
                                    categories={categories}/> }
                                <FAQSearch
                                    setString={setString}
                                    setSelectedIndex={setSelectedIndex}/>
                                { media?.type !== "mobile" && <DFAQCategories
                                                                setSelectedIndex={setSelectedIndex}
                                                                categories={categories}/> }
                                <FAQList
                                    list={list}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}/>      
                                </>
                            : <LoadingCover theme={"light"}/> 
                    }
                </div>
            </div>
        </>
    )
}

export default Faq