import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { LanguageContext } from "../../../../../../../../context/language"
import { ShopifyContext } from "../../../../../../../../context/shopify"
import { useTranslation } from "../../../../../../../../hooks/useTranslation"
import "./p-option-value.scss"

const POptionValue = ({ value }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { storeDisplay } = useContext(ShopifyContext)
    const { t } = useTranslation()
    const { productId } = useParams()
    const navigate = useNavigate()

    /* Functions */
    const handleClick = () => {
        const otherOptions = storeDisplay.variant.selectedOptions.filter(option => 
            option.name.toLowerCase() !== value.optionName.toLowerCase())
        const optionalVariants = storeDisplay.product.variants.filter(variant => 
            variant.availableForSale === true &&
            variant.selectedOptions.some(option => 
                option.name.toLowerCase() === value.optionName.toLowerCase() &&
                option.value.toLowerCase() === value.value.toLowerCase()))

        let bestMatch = null
        let highestScore = -1
        optionalVariants.forEach(variant => {
            let score = 0
            variant.selectedOptions.forEach(selectedOption => {
                if (otherOptions.some(option => 
                    option.name.toLowerCase() === selectedOption.name.toLowerCase() &&
                    option.value.toLowerCase() === selectedOption.value.toLowerCase())) {
                    score++
                }
            })
            if (score > highestScore) {
                highestScore = score
                bestMatch = variant
            }
        })
        if (productId && bestMatch?.idNumber) {
            if (lang === "he") {
                navigate(`/he/product/${productId}/${bestMatch?.idNumber}`)
            } else {
                navigate(`/product/${productId}/${bestMatch?.idNumber}`)
            }
        }   
    }

    /* JSX */
    return (
        <button
            onClick={handleClick}
            className={`p-option-value-container ${value.active ? "active" : ""} ${value.selected ? "selected" : ""}`}
            aria-pressed={value.selected}
            aria-label={`Select ${t(`pages.skateshop.products.options.${value?.optionName}.values.${value?.value}`)}`}>
            <p>{t(`pages.skateshop.products.options.${value?.optionName}.values.${value?.value}`)}</p>
        </button>
    )
}

export default POptionValue