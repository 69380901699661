import React, { useContext, useEffect } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { goToPageTop } from "../../lib/helpers"
import { LanguageContext } from "../../context/language"
import MetaTags from "../../components/meta-tags/meta-tags"
import HeroSection from "./hero-section/hero-section"
import "./mission.scss"

const Mission = () => {

    /* Global */
    const { lang, updateUrlLang } = useContext(LanguageContext)
    const { urllang } = useParams()
    const location = useLocation()
    const navigate = useNavigate()


    /* Triggers */
    useEffect(() => {
        updateUrlLang({ urllang, location, navigate })
    }, [])

    useEffect(() => {
        goToPageTop()
    }, [location])

    /* Functions */
    const name = lang === "he"
        ? "חנות הסקייטבורד מיס-שוגר / המשימה"
        : "Ms-Sugar Skateshop / Mission"

    const description = lang === "he"
        ? "מותג סקייטבורד ישראלי, עם סטייל אומנותי ומעורר השראה."
        : "An Israeli skateboard brand, with an artistic and inspiring vibe."

    const canonicalUrl = lang === "he"
        ? "https://ms-sugar.com/he/"
        : "https://ms-sugar.com/"

    /* JSX */
    return (
        <>
            <MetaTags name={name} description={description} canonicalUrl={canonicalUrl}/>
            <div className="page mission">
                <HeroSection/>
            </div>
        </>
    )
}

export default Mission