import React from "react"
import DPTVCover from "./d-p-t-v-cover/d-p-t-v-cover"
import "./d-p-t-video.scss"

const DPTVideo = ({ item }) => {

    /* JSX */
    return (
        <div className="d-p-t-video-container">
            <DPTVCover/>
            <video
                muted 
                pause={"true"}>
                <source src={item?.sources?.[0]?.url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default DPTVideo