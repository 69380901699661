import React, { createContext, useContext, useEffect, useState } from 'react'
import { get } from "../lib/fetch"
import { LanguageContext } from './language';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)

    /* Locale */
    const [settings, setSettings] = useState({})
    const [legalData, setLegalData] = useState([])
    const [faqData, setFaqData] = useState([])
    const [loadingWebsite, setLoadingWebsite] = useState(true)
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
    const [geoData, setGeoData] = useState({})
    const [dialog, setDialog] = useState({
        contact: {
            loading: false,
            success: false,
            err: false
        },
        exchange: {
            loading: false,
            success: false,
            err: false
        },
        notifyWhenAvailable: {
            loading: false,
            success: false,
            err: false
        },
        removeNotifyWhenAvailable: {
            loading: false,
            success: false,
            err: false
        }
    })

    /* Triggers */
    useEffect(() => {
        initWebsite()
    }, [])
    
    /* Functions */
    const initWebsite = async () => {
        const geoCurrency = await getCountryCurrency()
        const legalData = await getLegalData()
        const faqData = await getFaqData()
        const settings = await getSettings()
        setSettings(settings)
        setLegalData(legalData)
        setFaqData(faqData)
        setGeoData(prev => ({ ...prev, geoCurrency }))
        setLoadingWebsite(false)
    }

    const convertLegalArrayToObject = (items) => {
        const result = {};
      
        items?.forEach(item => {
          if (!result[item.type]) {
            result[item.type] = [...item.sections]
          } else {
            result[item.type] = result[item.type].concat(item.sections)
          }
        })
      
        return result
    }

    const getSettings = async () => {
        try {
            const { payload } = await get({ rout: "/api/settings" })
            return payload
        } catch (err) {
            console.log(err)
            return {}
        }
    }

    const getFaqData = async () => {
        try {
            const { payload } = await get({ rout: "/api/faq" })
            return payload
        } catch (err) {
            console.log(err)
            return {}
        }
    }

    const getLegalData = async () => {
        try {
            const { payload } = await get({ rout: "/api/legal" })
            return convertLegalArrayToObject(payload)
        } catch (err) {
            console.log(err)
            return {}
        }
    }

    const getCountryCurrency = async () => {
        const countryCode = lang === "he" ? "IL" : "US"
        try {
            const res = await fetch(`https://restcountries.com/v3.1/alpha/${countryCode}`)
            const curencyData = await res.json()
            return Object.keys(curencyData[0]?.currencies)[0] || {}
        } catch (err) {
            console.log(err)
            return {}
        }
    }

    const resetDialog = () => {
        setDialog({
            contact: {
                loading: false,
                success: false,
                err: false
            },
            exchange: {
                loading: false,
                success: false,
                err: false
            },
            notifyWhenAvailable: {
                loading: false,
                success: false,
                err: false
            }
        })
    }

    const payload = {
        loadingWebsite,
        isSideMenuOpen,
        dialog,
        setDialog,
        setIsSideMenuOpen,
        resetDialog,
        geoData,
        legalData,
        faqData,
        settings
    }

    return (
        <GlobalContext.Provider value={payload}>
            {children}
        </GlobalContext.Provider>
    )
}