import React from "react"
import MPImage from "./m-p-image/m-p-image"
import MPVideo from "./m-p-video/m-p-video"
import "./m-p-media.scss"

const MPMedia = ({ media }) => {

    /* JSX */
    return (
        <div className="m-p-media-container">
            { (media?.mediaContentType === "IMAGE") && <MPImage item={media}/> }
            { (media?.mediaContentType === "VIDEO") && <MPVideo item={media}/> }
        </div>
    )
}

export default MPMedia