import React, { useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { LanguageContext } from "../../context/language"
import "./not-found.scss"

const NotFound = () => {

    /* Google */
    const { lang } = useContext(LanguageContext)
    const navigate = useNavigate()

    /* Locale */
    const h1Text = lang === "he"
        ? "404"
        : "404"

    const pText = lang === "he"
        ? "מצטערים, העמוד לא קיים"
        : "Sorry, the page does not exist."

    const buttonText = lang === "he"
        ? "לחזור לעמוד הבית"
        : "Go to Home"

    const path = lang === "he"
        ? "/he/"
        : "/"

    /* JSX */
    return (
        <div className="not-found-container">
            <h1>{h1Text}</h1>
            <p>{pText}</p>
            <button onClick={() => navigate(path)}>{buttonText}</button>
        </div>
    )
}

export default NotFound