import React, { useContext, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { ShopifyContext } from "../../../../context/shopify"
import { LanguageContext } from "../../../../context/language"
import { useTranslation } from "../../../../hooks/useTranslation"
import "./p-desctiption.scss"

const PDesctiption = () => {

    /* Global */
    const { storeDisplay } = useContext(ShopifyContext)
    const { lang } = useContext(LanguageContext)
    const { t, fixForTranslation } = useTranslation()
    const navigate = useNavigate()

    /* Locale */
    const pDesctiptionContainerRef = useRef()


    /* Triggers */
    useEffect(() => {
        const cleanup = updateGripClick()
        return cleanup
    }, [storeDisplay, lang])

    /* Functions */
    const updateGripClick = () => {
        setTimeout(() => {
            if (pDesctiptionContainerRef.current) {
                const addGripClick = pDesctiptionContainerRef.current.querySelector('span#addGripClick')
                if (addGripClick) {

                    const isNotAvailableForSale = !storeDisplay?.variant?.availableForSale
                    if (isNotAvailableForSale) {
                        addGripClick.className = "hide"
                    } else {
                        addGripClick.className = ""
                        const handleAddGripClick = () => {
                            if (lang === "he") {
                                navigate('/he/product/8333929119999')
                            } else {
                                navigate('/product/8333929119999')
                            }
                        }
                        addGripClick.addEventListener('click', handleAddGripClick)
                
                        // Cleanup function to remove the event listener
                        return () => {
                            addGripClick.removeEventListener('click', handleAddGripClick)
                        }
                    }
                }
            }
        }, 0)
    }

  const title = fixForTranslation({ string: storeDisplay?.product?.title })

  /* JSX */
  if (lang === "en") {
    return (
        <div
            ref={pDesctiptionContainerRef}
            className="p-desctiption-container"
            dangerouslySetInnerHTML={{ __html: storeDisplay?.product?.descriptionHtml }}/>
    )
  } else {
    return (
        <div
            ref={pDesctiptionContainerRef}
            className="p-desctiption-container"
            dangerouslySetInnerHTML={{ __html: t(`pages.skateshop.products.list.${title}.description_html`) }}/>
    )
  }
}

export default PDesctiption  