import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { LanguageContext } from "../../../../../context/language"
import "./d-t-nav-item.scss"

const DTNavItem = ({ item }) => {

    /* Global */
    const { lang } = useContext(LanguageContext)
    const { section } = useParams()

    /* Locale */
    const path = lang === "he"
        ? `/he/terms/${item?.[lang]?.slug}/`
        : `/terms/${item?.[lang]?.slug}/`

    /* JSX */
    return (
        <Link
            className={"d-t-nav-item " + (section === item?.[lang]?.slug ? "active" : "")}
            to={path}
            aria-current={section === item?.[lang]?.slug ? "page" : undefined}> 
            {item?.[lang]?.title}
        </Link>
    )
}

export default DTNavItem