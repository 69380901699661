import React from "react"
import DPTImage from "./d-p-t-image/d-p-t-image"
import DPTVideo from "./d-p-t-video/d-p-t-video"
import "./d-p-thombnail.scss"

const DPThombnail = ({ media, mediaIndex, setActiveMedia, activeMedia }) => {

    /* Functions */
    const handleMouseEnter = () => {
        setActiveMedia(mediaIndex)
    }

    /* JSX */
    return (
        <div
            className={"d-p-thombnail-container " + (activeMedia === mediaIndex ? "active" : "")}
            onClick={handleMouseEnter}
            onMouseEnter={handleMouseEnter}>
            { (media?.mediaContentType === "IMAGE") && <DPTImage item={media}/> }
            { (media?.mediaContentType === "VIDEO") && <DPTVideo item={media}/> }
        </div>
    )
}

export default DPThombnail