import React, { useContext, useState } from "react"
import { ShopifyContext } from "../../../../context/shopify"
import { useTranslation } from "../../../../hooks/useTranslation"
import CCLoading from "./c-c-loading/c-c-loading"
import "./c-checkout.scss"

const CCheckout = ({ trigerDelete, loading }) => {

    /* Global */
    const { cart, checkout } = useContext(ShopifyContext)
    const { t } = useTranslation()

    /* Locale */
    const [localeLoading, setLocaleLoading] = useState(false)

    /* Functions */
    const handleCheckout = () => {
        if (checkout?.webUrl) {
            setLocaleLoading(true)
            window.location.href = checkout.webUrl // Redirect to Shopify checkout
        } else {
            console.error("Checkout URL is not available.")
        }
    }
    
    /* JSX */
    return (
        <button
            onClick={handleCheckout}
            className={"c-checkout-container " + (cart?.lineItems?.length > 0 && trigerDelete === -1 && loading === -1 ? "" : "lock")}>
            <CCLoading loading={localeLoading}/>
            <p>{t("pages.cart.checkout")}</p>
            <span className="material-symbols-outlined">shopping_cart_checkout</span>
        </button>
    )
}

export default CCheckout