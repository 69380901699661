import React from "react"
import { Img } from "react-image"
import "./d-p-r-s-image.scss"

const DPRSImage = ({ item }) => {

    /* JSX */
    return (
        <Img
            className="d-p-r-s-image"
            src={item?.image?.originalSrc}
            loading="lazy"
            alt={item?.alt} />
    )
}

export default DPRSImage