import React, { createContext, useContext, useEffect, useState } from 'react'
import { ShopifyContext } from './shopify'

export const CurrencyContext = createContext()

export const CurrencyProvider = ({ children }) => {

    /* Global */
    const { storeCurrency } = useContext(ShopifyContext)

    /* Locale */
    const [currency, setCurrency] = useState(() => {
        // const savedCurrency = localStorage.getItem('currency')
        // const navigatorCurrency = (navigator.language === "he" || navigator.language === "he-IL") ? "ILS" : "USD"
        // return savedCurrency ? savedCurrency : navigatorCurrency
        return "ILS"
    })
    const [currencyOptions] = useState([
        {currency: "ILS", symbol: "₪"},
        {currency: "USD", symbol: "$"},
        {currency: "EUR", symbol: "€"}
    ])
    const [conversionRates, setConversionRates] = useState({})

    /* Triggers */
    useEffect(() => {
        updateConversionRates()
    }, [storeCurrency])

    useEffect(() => {
        updateCurrency()
    }, [currency, conversionRates])

    /* Functions */
    const updateConversionRates = async () => {
        if (storeCurrency) {
            try {
                const response = await fetch(`https://v6.exchangerate-api.com/v6/b15ff6be34deaaeda579b42c/latest/${storeCurrency}`)
                const rates = await response.json()
                setConversionRates(rates?.conversion_rates)
            } catch (err) {
                setCurrency(storeCurrency)
                console.log(err)
            }
        }
    }

    const getConversion = async ({ to, amount }) => {
        if (conversionRates[to]) {
            const convertedAmount = amount * conversionRates[to]
            return convertedAmount
        } else {
            const convertedAmount = amount * 1
            return convertedAmount
        }
    }

    const updateCurrency = async () => {
        if (conversionRates?.[currency]) {
            localStorage.setItem('currency', currency)
        } else {
            localStorage.setItem('currency', storeCurrency)
        }
    }

    const payload = {
        currency,
        setCurrency,
        currencyOptions,
        getConversion,
        conversionRates
    }

    return (
        <CurrencyContext.Provider value={payload}>
            {children}
        </CurrencyContext.Provider>
    )
}