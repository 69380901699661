import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import { GlobalContext } from "../../../../context/global"
import { post } from "../../../../lib/fetch"
import "./p-remove-notification.scss"

const PRemoveNotification = () => {

    /* Global */
    const { setDialog } = useContext(GlobalContext)
    const location = useLocation()
    const navigate = useNavigate()

    /* Locale */
    const [email, setEmail] = useState(null)
    const [productId, setProductId] = useState(null)

    /* Triggers */
    useEffect(() => {
        updateParams()
    }, [location, navigate])

    useEffect(() => {
        updateActive()
    }, [email, productId])

    /* Functions */
    const updateParams = () => {
        const queryParams = new URLSearchParams(location.search)
        setEmail(queryParams.get('email') || null)
        setProductId(queryParams.get('productId') || null)
        if (queryParams.has('email') || queryParams.has('productId')) {
            navigate(location.pathname)
        }
    }

    const updateActive = async () => {
        if (email && productId) {
            setDialog(prev => ({...prev, removeNotifyWhenAvailable: {...prev.removeNotifyWhenAvailable, loading: true}}))
            const res = await post({
                data: {email, productId},
                rout: "/api/product/unsubscribe" })
            setDialog(prev => ({...prev, removeNotifyWhenAvailable: {...prev.removeNotifyWhenAvailable, loading: false}}))
            if (res.err) {
                setDialog(prev => ({...prev, removeNotifyWhenAvailable: {...prev.removeNotifyWhenAvailable, err: true, res}}))
            }
            if (res.payload) {
                setDialog(prev => ({...prev, removeNotifyWhenAvailable: {...prev.removeNotifyWhenAvailable, success: true, res}}))
            }
        }
    }
}

export default PRemoveNotification