import React, { useContext } from "react"
import { GlobalContext } from "../../../../context/global"
import ContacrSuccessMessage from "./contact-success-message/contact-success-message"
import ContactErrorMessage from "./contact-error-message/contact-error-message"
import ExchangeSuccessMessage from "./exchange-success-message/exchange-success-message"
import ExchangeErrorMessage from "./exchange-error-message/exchange-error-message"
import NotifyAvailableSuccessMessage from "./notify-available-success-message/notify-available-success-message"
import NotifyAvailableErrorMessage from "./notify-available-error-message/notify-available-error-message"
import RemoveNotifyAvailableSuccessMessage from "./remove-notify-available-success-message/remove-notify-available-success-message"
import RemoveNotifyAvailableErrorMessage from "./remove-notify-available-error-message/remove-notify-available-error-message"
import "./dialog-body.scss"

const DialogBody = () => {

    /* Global */
    const { dialog } = useContext(GlobalContext)

    /* JSX */
    return (
        <div className="dialog-body-container">
            {dialog?.contact?.success && <ContacrSuccessMessage/>}
            {dialog?.contact?.err && <ContactErrorMessage/>}
            {dialog?.exchange?.success && <ExchangeSuccessMessage/>}
            {dialog?.exchange?.err && <ExchangeErrorMessage/>}
            {dialog?.notifyWhenAvailable?.success && <NotifyAvailableSuccessMessage/>}
            {dialog?.notifyWhenAvailable?.err && <NotifyAvailableErrorMessage/>}
            {dialog?.removeNotifyWhenAvailable?.success && <RemoveNotifyAvailableSuccessMessage/>}
            {dialog?.removeNotifyWhenAvailable?.err && <RemoveNotifyAvailableErrorMessage/>}
        </div>
    )
}

export default DialogBody