import { useContext, useState, useEffect } from "react"
import { useTranslation } from "./useTranslation"
import { LanguageContext } from "../context/language"
import { CurrencyContext } from "../context/currency"
import { formatPrice } from "../lib/helpers"

export const useShipping = () => {

    /* Global */
    const { currency, getConversion, conversionRates } = useContext(CurrencyContext)
    const { lang } = useContext(LanguageContext)
    const { t } = useTranslation()

    /* Locale */
    const [shippingInfo, setShippingInfo] = useState()
    // const shippingInfo = {
    //     shippingThreshold: "$50",
    //     countriesList: lang === "he"
    //         ? "ישראל"
    //         : "Israel",
    // }

    /* Triggers */
    useEffect(() => {
        updateAmount()
    }, [currency, conversionRates])

    /* Functions */
    const updateAmount = async () => {
        const thresholdINS = 200
        const convertedAmount = await getConversion({ to: currency, amount: thresholdINS })
        // setAmount(convertedAmount)
        setShippingInfo({
            shippingThreshold: formatPrice(convertedAmount, currency),
            countriesList: lang === "he"
                ? "ישראל"
                : "Israel",
        })
    }

    const getShippingMessage = () => {
        return t("pages.cart.shipping_message_with_no_country")
                .replace("{countriesList}", shippingInfo?.countriesList)
                .replace("{shippingThreshold}", shippingInfo?.shippingThreshold)
    }

    return getShippingMessage()

}